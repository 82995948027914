.header-container{
    width: 100%;
    position: relative;
    z-index: 20;
}
.logos{
    padding-top: 17px;
    padding-bottom: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.yellow-banner{
    background-color: #FFD100;
    height: 40px;
}
.yellow-banner p{
    color: #001489;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.5px;
}
.abott-logo-container{
    display: flex !important;
    justify-content: center;
    align-items: center;

}
.main-logo-container{
    max-height: 8vw;
}
.abott-logo-container img{
    max-height: 8vw;
}