@font-face {
  font-family: 'Helvetica';
  src: url('/public/fonts/helvetica-255/Helvetica.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
*{
  font-family: 'Helvetica' !important;
}
.App {
  text-align: center;
  font-family: "Helvetica" !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
body{
  background-color: #F3F1EE;
}
.main_container{
  margin-top: 80px;
  border-radius: 35px;
  background-color: #fff;
  box-shadow: '0px 691px 194px 0px rgba(0, 0, 0, 0.00), 0px 442px 177px 0px rgba(0, 0, 0, 0.01), 0px 249px 149px 0px rgba(0, 0, 0, 0.05), 0px 111px 111px 0px rgba(0, 0, 0, 0.09), 0px 28px 61px 0px rgba(0, 0, 0, 0.10)';
  padding:0 !important;
}
.pages_container{
  padding: 64px 48px 0 48px;
}
.page-contianer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.ps-48{
  padding-left: 48px;
  padding-right: 48px;
}
.text-white{
    color:#fff !important;
}
.rounded-btn{
  max-width: 100%;
  height: 48px !important;
  border-radius: 500px !important;
  /* color: #fff !important; */
  text-align: center !important;
  font-feature-settings: 'clig' off, 'liga' off !important;
  font-family: Helvetica !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 17.5px !important; /* 17.5px */
  letter-spacing: 0.75px !important;
  /*text-transform: unset !important;*/
  text-transform: uppercase !important;
  text-wrap: nowrap;
  }


.organg-panel{
  display: flex;
  padding: 24px;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: 24px;
  flex-wrap: wrap;
  border-radius: 25px;
  background: #FFD100;
  margin-bottom: 24px !important;
}
  .main-title{
    color: #001489 !important;
    font-family: Helvetica !important;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 130% !important; /* 31.2px */
    text-align:left;
  }
  .main-title-black{
    color: #000 !important;
    font-feature-settings: 'clig' off, 'liga' off !important;
    font-family: Helvetica !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 130% !important; /* 26px */
    letter-spacing: 0.5px !important;
  }
  .secondary-text{
    color: #000 !important;
    font-family: Helvetica !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 130% !important;
    text-align:left;
    width: 100%;
  }
  .secondary-light-text{
    color: #2b2b2b !important;
    font-family: Helvetica !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 130% !important;
    text-align:left;
  }

  .blue-bold{
    color: #001489 !important;
    font-family: Helvetica !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 130% !important; /* 20.8px */
  }
  a.blue-bold{
    text-decoration: none;
  }
  .form-group{
    margin-left:88px;
    margin-right:88px;
    margin-bottom:24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex-shrink: 0;
  }
  .horizontal-form {
    margin:0;
    flex-direction: row;
    gap:24px;
  }
  .danger-txt{
    color: #E95526 !important;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Helvetica !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 130% !important; /* 18.2px */
    letter-spacing: 0.5px !important;
  }
  .radio-label,.choise-labe{
    color: #000 !important;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Helvetica !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 130% !important; /* 18.2px */
    letter-spacing: 0.5px !important;
  }
  .radio-label.Mui-error,.Mui-error svg{
    color: #E95526 !important;
  }
  .Mui-error *{
    border-color:#E95526 !Important;
  }
  label.Mui-error,.MuiFormHelperText-root.Mui-error{
    color:#E95526 !important;
    -webkit-text-fill-color:#E95526 !important;
  }
  .MuiTextField-root{
    margin-top:24px !important;
  }
  .validation-error{
    color: #E95526 !important;
    font-size: 14px !important;
    display: flex;
    align-items: center;
  }
  .validation-error img{
    margin-right: 8px;
  }
  .MuiFormHelperText-root.Mui-error::before {
    content: url('./../public/warningIcon.svg'); /* Replace with your base64 encoded SVG */
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
  }
    .MuiAutocomplete-listbox li.Mui-focused{
      background-color: #FFD100 !important;
    }
  /* .MuiTextField-root .MuiInputLabel-formControl{
    top: -42px !important;
    left: -14px !important;
  } */
@media(max-width:768px) {
  .main_container{
    margin-top: 0;
    border-radius: 0;
  }
  .ps-48{
    padding-left: 16px;
    padding-right: 16px;
  }
  .form-group{
    margin-left:0;
    margin-right:0;
  }
  .pages_container{
    padding: 36px 16px 0 16px;
  }
  .horizontal-form {
    flex-direction: column;
  }
  .pages_container{
    overflow: hidden;
  }
  .upper-section{
  gap: 24px !important;
  }
  .mobile-row{
    flex-direction: row;
  }
  .mobile-row button{
    align-self: end;
  }
  .MuiTextField-root{
    width: 100%;
  }
  .footer-container{
    padding: 48px 16px !important;
  }
  .footer-container .navigations{
  gap: 24px;
  }
}

.email-confirmation{
  padding: 0;
}
.form-container *{
  font-family: Helvetica !important;
}
label{
  color: #000 !important;
}
fieldset{
  border: 1px solid #000 !important;
}
.Mui-disabled{
  -webkit-text-fill-color:black !important
}
.MuiInputLabel-root{
  -webkit-text-fill-color: black !important;
}
::placeholder{
  color: #9c9894 !important;
  opacity: 1 !important;
}
.MuiInputBase-input{
  font-size: 14px !important;
}
label{
  font-size: 20px !important;
  transform: translate(14px, -9px);
}
.radio-label, .choise-label{
 font-size: 16px !important;
}
.MuiCheckbox-root svg[data-testid='CheckBoxOutlineBlankIcon'] path{
 fill: #2E3546;
}
.checkbox-icon{
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}
.ordered-list{
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 12px 0;
}
.submit-buttons,.above-others{
  position: relative;
  z-index: 20;
}
