.footer-container {
    width: calc(100% + 96px);
    background-color: black;
    padding: 48px;
    box-sizing: border-box;
    color: white;
    position: relative;
    z-index: 20;
    margin: 0 -48px 0 0;
}

.navigations {
    display: flex;
    gap: 48px;
    flex-wrap: wrap;
    color: #FFD100;

}
@media(max-width:768px) {
    .navigations {
        flex-direction: column;
    }
    .footer-container{
        width: calc(100% + 32px) !important;
        margin: 48px 0 0 -16px !important;
    }
}
.navigations a {
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
     letter-spacing: 0.5px;
    cursor: pointer;
    color: #FFD100;
    text-decoration: none;
}

.horizontal-line {
    background-color: #525252 !important;
    margin: 24px 0;
}

.logo-footer {
    margin-bottom: 24px;

}

.other-navigations {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
}

.other-navigations a {
    font-family: Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.5px;
    text-decoration: none;
    color: white;
}
.footer-text{
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    font-size:12px ;
    line-height: 140%;
}
