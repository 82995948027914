.title{
    color: #001489;
    font-family: Helvetica;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: 0.5px;   
}
.bold{
    font-family: Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: 0.5px;
}
.column1{
    width: 70%;
}
.column2{
    width: 30%;
    display: flex;
    justify-content: center;
}
.column2 img{
    width: 147px;
    height: 147px;
}
.row{
    display: flex;
}
.hyperlink{
    text-decoration: underline;
    color: blue;
}
.my-24{
    margin: 24px 0;
}
@media(max-width:768px){
    .column1{
        width: 100%;
    }
    .column2{
      display: none;
    }

}