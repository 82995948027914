.slider-container {
    width: 100%;
    margin: auto;
  }
  
  .slider-image {
    @media(max-width:768px) {
      width: 75%;
    }
    width: 100%;
    height: auto;
  }
  .swiper-pagination-bullet{
    background-color: white;
    border: 2px solid #FFD100;
    border-radius: 50%;
    opacity: 1;
  }
  .swiper-pagination-bullet-active{
    background-color: #FFD100;
  }
  .swiper-slide{
    display: flex;
    justify-content: center;
  }
  .swiper-pagination{
    transform: translateY(10px);
  }
  .swiper{
    margin-bottom: 24px;
  }