.upper-section{
    display: flex;
    padding: 12px 0px 24px 0px;
    justify-content: center;
    align-items: flex-start;
    gap: 45px;
}
.upper-section .right-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 17px;
}
.right-box img{
    height: 149px;
}
.upper-section-texts{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}
.upper-section-texts .blue-title{
    color: #001489;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Helvetica;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height:39px;
    letter-spacing: 0.5px;
}
.upper-section-texts .main-text {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 20.8px */
    letter-spacing: 0.5px;
}
.upper-section-texts .secondary-text {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 20.8px */
    letter-spacing: 0.5px;
}
.organg-with-icon{
   cursor: pointer;
   flex-direction: row !important;
   flex-wrap: nowrap;
}
.icon-container > img{
    height: 40px;
}
.organg-with-icon .main-title{
    line-height: 140% !important;
}
.organg-with-icon .organg-with-icon-content{
  display: flex;
  flex-direction: column;
  gap: 0;
}
.organg-with-icon .organg-with-icon-content .blue-bold{
border-bottom: 1px solid #001489;
cursor: pointer;
}
.collapsed-accordion {
    max-height: 1000px;
    transition: max-height 2s ease;
    overflow: hidden;
}

.closed-accordion {
    max-height: 0;
    transition: max-height 1s ease;
    overflow-y: hidden;
}
.unordered-list-accordion{
    font-size: 16px;
    padding-left: 17px;
    margin:0 ;
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.bold-700{
    font-weight: 700 !important;
}
.mb-24{
    margin-bottom: 24px !important;
}
.form-container{
    max-width: 100%;
}
@media(max-width:768px) {
    .organg-with-icon{
        flex-direction: column !important;
    }
    .upper-section{
        flex-direction: column;
    }
    .right-box img {
        display: none;
    }
}
.rounded-btn-success{
    background-color: #9C9894 !important;

}
.rounded-btn-success-message{
    margin-top: 24px;
    color: #000;
    font-family: Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: 0.5px;
}
.rounded-btn-failed{
    background-color: #E95526 !important;
    color: white !important;
}
.rounded-btn-failed-message{
    margin-top: 24px;
    color:#E95526;
    font-family: Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: 0.5px;
}
.image-info{
    font-size: 10px !important;
    margin-top: 24px !important;
}
.download-text-small{
    font-size: 12px !important;
    color: black !important;
    -webkit-text-fill-color:black !important;
}
.texts-container{
    display: flex;
    flex-direction: column;
    gap: 12px;
}
